<template>
  <!-- scale-transition -->
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :max-width="maxWidth"
    :scrollable="scrollable"
    transition="dialog-bottom-transition"
  >
    <!--    <template v-slot:default="dialog"-->
    <template v-slot:default>
      <v-card :loading="displayProgress">
        <base-dialog-header
          v-show="visibleHeader"
          :icon-color="iconColor"
          :title-color="titleColor"
        >
          <!-- dialog title -->
          <slot name="default"></slot>

          <!-- dialog icon -->
          <template v-slot:icon-name v-if="hasSlot('icon-name')">
            <slot name="icon-name"></slot>
          </template>

          <!-- action - icon-cross (close dialog) -->
          <template v-slot:icon-cross v-if="hasSlot('icon-cross')">
            <slot name="icon-cross"></slot>
          </template>
        </base-dialog-header>

        <!-- Dialog header divider -->
        <v-divider></v-divider>

        <!-- pre-context -->
        <slot name="pre-context"></slot>

        <!-- context -->
        <v-card-text>
          <slot name="context"></slot>
        </v-card-text>

        <!-- Dialog actions divider -->
        <v-divider></v-divider>

        <v-card-actions>
          <slot name="actionsLeft"></slot>

          <v-spacer></v-spacer>

          <slot name="actions"></slot>
          <slot name="actionCancel"></slot>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";
import { Color } from "@/design/colors/Color";

export default {
  name: "DialogOutline",
  inheritAttrs: false,
  mixins: [slottableMixin],
  components: {
    BaseDialogHeader: () => import("@/components/shared/base/BaseDialogHeader")
  },
  props: {
    maxWidth: {
      type: String,
      default: "500px"
    },
    visibleHeader: {
      type: Boolean,
      default: true
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    /**
     * display liner progress
     */
    displayProgress: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: Color,
      default: undefined
    },
    titleColor: {
      type: Color,
      default: undefined
    }
  }
};
</script>
